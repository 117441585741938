import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Title,
  Input,
  Button,
  InputLabel,
  InputContainer,
  ErrorMessage,
  EyeIcon,
  LoadingIcon,
} from "./StyledComponent";
import { RiEyeFill, RiEyeCloseLine } from "react-icons/ri";
import LoginService from "../api/LoginService";
import { colors } from "./Constants";
const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, userId, accessCode } = location.state || {};
  //FIXME - console
  console.log("Received data from ForgetPassword:", {
    email,
    userId,
    accessCode,
  });
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const validatePassword = (password) => {
    return (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[\W_]/.test(password)
    );
  };

  const validateOnBlur = (fieldName) => {
    const error = { ...errorMessages };

    switch (fieldName) {
      case "newPassword":
        error.newPassword = validatePassword(newPassword)
          ? ""
          : "Password must be at least 8 characters, include a number, an uppercase letter, and a special character.";
        break;
      case "confirmPassword":
        error.confirmPassword =
          confirmPassword === newPassword ? "" : "Passwords do not match.";
        break;
      default:
        break;
    }

    setErrorMessages(error);
  };

  const handleSubmit = async () => {
    const errors = {
      newPassword: validatePassword(newPassword)
        ? ""
        : "Password must be at least 8 characters, include a number, an uppercase letter, and a special character.",
      confirmPassword:
        confirmPassword === newPassword ? "" : "Passwords do not match.",
    };

    setErrorMessages(errors);

    if (Object.values(errors).some((error) => error)) {
      return;
    }

    setLoading(true);

    try {
      const response = await LoginService.resetPassword({
        UserId: userId,
        NewPassword: newPassword,
        ConfirmPassword: confirmPassword,
        AccessCode: accessCode,
      });

      setLoading(false);

      if (response.IsSuccess) {
        alert("Password reset successfully!");
        navigate("/login");
      } else {
        alert(response.Message || "Password reset failed. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during password reset:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="container">
      <Title>Reset Password</Title>
      <InputContainer>
        <Input
          type={showNewPassword ? "text" : "password"}
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
            setErrorMessages((prev) => ({ ...prev, newPassword: "" }));
          }}
          onBlur={() => validateOnBlur("newPassword")}
        />
        <EyeIcon onClick={() => setShowNewPassword((prev) => !prev)}>
          {showNewPassword ? <RiEyeFill /> : <RiEyeCloseLine />}
        </EyeIcon>
        <InputLabel>New Password</InputLabel>
      </InputContainer>
      {errorMessages.newPassword && (
        <p
          style={{ color: colors.error, fontSize: "10px", fontWeight: "bold" }}
        >
          {errorMessages.newPassword}
        </p>
      )}
      <InputContainer>
        <Input
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setErrorMessages((prev) => ({ ...prev, confirmPassword: "" }));
          }}
          onBlur={() => validateOnBlur("confirmPassword")}
        />
        <EyeIcon onClick={() => setShowConfirmPassword((prev) => !prev)}>
          {showConfirmPassword ? <RiEyeFill /> : <RiEyeCloseLine />}
        </EyeIcon>
        <InputLabel>Confirm Password</InputLabel>
      </InputContainer>
      {errorMessages.confirmPassword && (
        <p
          style={{ color: colors.error, fontSize: "10px", fontWeight: "bold" }}
        >
          {errorMessages.confirmPassword}
        </p>
      )}
      <Button onClick={handleSubmit} disabled={loading}>
        {loading ? <LoadingIcon /> : "Save"}
      </Button>
    </div>
  );
};

export default ResetPassword;
