import { sharedInstance } from "../api/axios";
const SignUpService = {
  //SECTION - register users -> send OTP
  signUp: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneRegistration",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during sign up:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - Validate OTP
  sendActivationCode: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneValidateOTP",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during send activation code:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - resend OTP
  resendActivationCode: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneResendOTP",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during resend activation code:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
};
export default SignUpService;
