import { sharedInstance } from "./axios";

const LoginService = {
  //SECTION - login user
  signIn: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneLogin",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during login:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  //SECTION - forget password
  forgetPassword: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneForgotPassword",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during forget password request:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - reset password
  resetPassword: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneResetPassword",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during reset password request:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - SIM card activation
  validateSimCard: async (iccid) => {
    try {
      const response = await sharedInstance.get(
        "/api/Activation/ValidateSimCard",
        {
          params: { iccid },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during SIM card validation:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //FIXME -  fix this later ->

  userProfile: async (data) => {
    try {
      const response = await identityInstance.get("/Auth/v1/user_profile", {
        params: data,
      });
      return response;
    } catch (error) {
      console.error("Error fetching user profile:", error);
      throw error;
    }
  },

  updatePassword: async (data) => {
    try {
      const response = await identityInstance.put(
        "/Auth/v1/update_password",
        data
      );
      return response;
    } catch (error) {
      console.error("Error updating password:", error);
      throw error;
    }
  },

  updateProfile: async (data) => {
    try {
      const response = await identityInstance.put(
        "/Auth/v1/update_profile",
        data
      );
      return response;
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  },

  updateEmail: async (data) => {
    try {
      const response = await identityInstance.put(
        "/Auth/v1/update_email",
        data
      );
      return response;
    } catch (error) {
      console.error("Error updating email:", error);
      throw error;
    }
  },
};

export default LoginService;
